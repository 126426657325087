@import '_variables';

@font-face {
  font-family: 'IBMPlexSans';
  font-style: normal;
  font-weight: 100 900;
  font-display: fallback;
  src: url(/assets/fonts/IBMPlexSansVar-Roman.woff2) format('woff2');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
}

#__next {
  background-color: var(--color-dark-blue);
  position: relative;
  z-index: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

strong {
  font-weight: var(--font-weight-semibold);
  font-variation-settings: 'wght' var(--font-weight-semibold);
}

.textHighlight {
  color: var(--color-yellowish);
  font-weight: var(--font-weight-semibold);
  font-variation-settings: 'wght' var(--font-weight-semibold);
}

.lc_callback_widget {
  display: none;
}

.menu-open {
  overflow: hidden;
}

// hide elements visually but not for screen-readers, for accessibility
.u-a11y-hide {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

#filter-modal {
  position: relative;
  z-index: 2147483002; // 1 + higher than intercom css z-index
}
.hs-form-iframe {
  min-height: 700px;
}
