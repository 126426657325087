:root {
  /*
   * Colors
   */
  --color-sky-blue-900: #006184;
  --color-sky-blue-700: #0087b8;
  --color-sky-blue-500: #00abe9;
  --color-sky-blue-300: #5acdf6;
  --color-sky-blue-100: #a3dff5;
  --color-sky-blue: var(--color-sky-blue-500);

  --color-mint-sweet-900: #0f8a68;
  --color-mint-sweet-700: #14b88b;
  --color-mint-sweet-500: #19e4ac;
  --color-mint-sweet-300: #5eedc6;
  --color-mint-sweet-100: #a3f5de;
  --color-mint-sweet: var(--color-mint-sweet-500);

  --color-redish-900: #aa4527;
  --color-redish-700: #dd5127;
  --color-redish-500: #ee6e48;
  --color-redish-300: #f29478;
  --color-redish-100: #f7b9a6;
  --color-redish: var(--color-redish-500);

  --color-yellowish-900: #99c12f;
  --color-yellowish-700: #b6e240;
  --color-yellowish-500: #cdf75d;
  --color-yellowish-300: #dcf98f;
  --color-yellowish-100: #ebfcc0;
  --color-yellowish: var(--color-yellowish-500);

  --color-dark-blue-900: #000033;
  --color-dark-blue-700: #2e2e57;
  --color-dark-blue-500: #5c5c7b;
  --color-dark-blue-300: #89899d;
  --color-dark-blue-100: #b7b7c1;
  --color-dark-blue: var(--color-dark-blue-900);

  --color-stone-grey-900: #e8e8e7;
  --color-stone-grey-700: #ebebe9;
  --color-stone-grey-500: #efefeb;
  --color-stone-grey-300: #f2f2ed;
  --color-stone-grey-100: #f5f5ef;
  --color-stone-grey: var(--color-stone-grey-100);

  --color-white: #fff;
  --color-black: #000;

  --color-error: #ee6e48;
  --color-warning: #ffa944;
  --color-valid: #02bf86;

  --color-transparent: transparent;

  --color-text-primary: var(--color-dark-blue);
  --color-text-inverted: var(--color-white);

  --color-flow-symbol: #57c7f0;

  /*
   * Sizes
   */
  --max-size-lg: 84rem;
  --max-size-md: 80rem;
  --max-size-sm: 70rem;

  --vertical-gutter-negative: -3rem;
  --vertical-gutter-tiny: 1rem;
  --vertical-gutter-small: clamp(1rem, 5vw, 2rem);
  --vertical-gutter-medium: clamp(2rem, 10vw, 5rem);
  --vertical-gutter-large: clamp(4.5rem, 12vw, 8.125rem);
  --vertical-gutter-xlarge: clamp(8rem, 18vw, 14rem);

  --horizontal-gutter-tiny: 0.5rem;
  --horizontal-gutter-small: 1rem;
  --horizontal-gutter: 1.5rem;

  @media (min-width: 71.5em) {
    --vertical-gutter-negative: -7.25rem;
    --horizontal-gutter: auto;
  }

  /*
   * Fonts
   * See font-stack mixin for sizes etc
   */
  --font-family: 'IBMPlexSans', sans-serif;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-weight-light: 300;

  /*
   * Borders
   */
  --border-radius-large: 2rem;

  /*
   * Layers (z-index)
   */
  --layer-andromeda: 50;
  --layer-sun: 40;
  --layer-moon: 30;
  --layer-sky: 20;
  --layer-hill: 10;
  --layer-ground: 0;
  --layer-soil: -10;
  --layer-crust: -20;
  --layer-magma: -30;
  --layer-core: -40;

  /*
 * Shadows
 */
  --elevation-high: 0px 5px 32px -4px rgba(58, 61, 85, 0.05),
    0px 3px 20px -2px rgba(58, 61, 85, 0.1), 0px 7px 16px -2px rgba(58, 61, 85, 0.12);
  --elevation-medium: 0px 0px 8px -1px rgba(58, 61, 85, 0.08),
    0px 9px 11px -3px rgba(58, 61, 85, 0.08), 0px 2px 16px -2px rgba(58, 61, 85, 0.14);
  --elevation-low: 0px 0px 6px -1px rgba(58, 61, 85, 0.08), 0px 3px 6px -3px rgba(0, 0, 0, 0.11);
}
